.rotaterForward {
  animation: rotate 1.8s ease-in-out;
}

@keyframes rotate {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.rotaterBack {
  animation: rotateBack 1.8s ease-in-out;
}

@keyframes rotateBack {
  from {
    transform: rotate(-180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.growText {
  transform-origin: right center; /* Set the transform origin to the right center */
  transform: scaleX(0); /* Initial scale along the X-axis is 0 */
  animation: scaleAnimation 2s forwards 1.4s; /* Apply the animation */
}
@keyframes scaleAnimation {
  0% {
    transform: scaleX(0); /* Start with no scaling (0) */
  }
  100% {
    transform: scaleX(1); /* End with full scaling (1) */
  }
}

.visibleTitleText{
  opacity: 0;
  animation: visibleTexttitle 1.5s ease-in-out forwards 1.8s;
}
@keyframes visibleTexttitle {
  0% {
    opacity: 0;
  }
  25%{
    opacity: 0.2;
  }
  50%{
    opacity: 0.5;
  }
  75%{
    opacity: 0.7;
  }
  
  100% {
    opacity: 1;
  }
}

.visibleImg{
  opacity: 0;
  animation: visible 2s ease-in-out forwards 1.3s;
}
@keyframes visible {
  0% {
    opacity: 0;
  }
  25%{
    opacity: 0.2;
  }
  50%{
    opacity: 0.5;
  }
  75%{
    opacity: 0.7;
  }
  
  100% {
    opacity: 1;
  }
}

.moveIn {
  transform: translateX(200%);
  animation: movein 1.2s ease-in-out 1s forwards;
}

@keyframes movein {
  from {
    transform: translateX(120%);
  }

  to {
    transform: translateX(0%);
  }
}

.moveUp {
  transform: translateY(500%);
  animation: moveup 1.2s ease-in-out 1s forwards;
}

@keyframes moveup {
  from {
    transform: translateY(500%);
  }

  to {
    transform: translateY(0%);
  }
}

.contactMove {
  transform: translateX(-150%);
  animation: moveincontact 1.2s ease-in-out 1s forwards;
}

@keyframes moveincontact {
  from {
    transform: translateX(-150%);
  }

  to {
    transform: translateX(0%);
  }
}

.bounce {
  animation: bouncing 1.5s ease-in-out infinite;
}

@keyframes bouncing {
  0%, 100% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-18%);
  }
}

/* mobile animatons */
.growLineMob {
  transform-origin: top center; /* Set the transform origin to the right center */
  transform: scaleY(0); /* Initial scale along the X-axis is 0 */
  animation: scaleLine 2s forwards 1.4s; /* Apply the animation */
}
@keyframes scaleLine {
  0% {
    transform: scaleY(0); /* Start with no scaling (0) */
  }
  100% {
    transform: scaleY(1); /* End with full scaling (1) */
  }
}

.growLineUpMob {
  transform-origin: bottom center; /* Set the transform origin to the right center */
  transform: scaleY(0); /* Initial scale along the X-axis is 0 */
  animation: scaleLineUp 2s forwards 1.4s; /* Apply the animation */
}
@keyframes scaleLineUp {
  0% {
    transform: scaleY(0); /* Start with no scaling (0) */
  }
  100% {
    transform: scaleY(1); /* End with full scaling (1) */
  }
}

/* thumbnail slider img */
.thumbnailMove {
  transform: translateX(100%);
  animation: moveinthumbnail 0.2s ease-in-out 0s forwards;
}

@keyframes moveinthumbnail {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.thumbnailMoveBack {
  transform: translateX(-100%);
  animation: moveinthumbnailback 0.2s ease-in-out 0s forwards;
}

@keyframes moveinthumbnailback {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

.overlay {
  position: absolute;
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  background-color: transparent;
  pointer-events: none;
  border: 1px solid #000; /* Add a border for visibility */
  transform: translate(-50%, -50%);
  transition: background-color 0.3s;
}